<template>
  <base-tooltip-button
    :fab="true"
    :small="true"
    :color="deleteColorClass"
    :disabled="disabled"
    @click="$emit(eventNameClick)"
  >
    <template v-slot:icon-name>
      {{ iconName }}
    </template>
    <template v-slot:toolTip>
      {{ toolTip }}
    </template>
  </base-tooltip-button>
</template>

<script>
// design
import { iconClose } from "@/design/icon/iconConst";

// model
import { eventNames } from "@/model/common/events/eventConst";

// mixins
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";

export default {
  name: "RemoveFieldButton",
  mixins: [deleteSettingsMixin],
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  props: {
    /**
     * Form Field
     * @type {{id: number, name: string, label: string}}
     */
    field: undefined,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconName: iconClose,
      eventNameClick: eventNames.click
    };
  },
  computed: {
    fieldLabel() {
      return this.field?.label ?? this.field?.name ?? "Field";
    },
    toolTip() {
      return `Remove ${this.fieldLabel}`;
    }
  }
};
</script>
